import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/amahony/Sites/reactberry/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { SegmentList, Segment } from '.';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "segment"
    }}>{`Segment`}</h1>
    <Props of={Segment} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<SegmentList initialValue=\"B\">\n  <Segment name=\"A\">A</Segment>\n  <Segment name=\"B\">B</Segment>\n  <Segment name=\"C\">C</Segment>\n  <Segment name=\"D\">D</Segment>\n</SegmentList>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SegmentList,
      Segment,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <SegmentList initialValue="B" mdxType="SegmentList">
    <Segment name="A" mdxType="Segment">A</Segment>
    <Segment name="B" mdxType="Segment">B</Segment>
    <Segment name="C" mdxType="Segment">C</Segment>
    <Segment name="D" mdxType="Segment">D</Segment>
  </SegmentList>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      